
import { Vue, Component, Prop } from "vue-property-decorator";
import { SmartText, WYSIWYG } from "_components";
import { DCTextBlock, DCTextModules } from "./types";

const TEXT_MAP = {
  [DCTextModules.HEADLINE]: "hero-p-large",
  [DCTextModules.LARGE]: "hero-p-large",
  [DCTextModules.NORMAL]: "hero-p",
  [DCTextModules.SMALL]: "hero-p-small",
};

@Component({
  name: "DynamicContentTextModule",
  components: {
    SmartText,
    WYSIWYG,
  },
})
export default class DynamicContentTextModule extends Vue {
  @Prop({ required: true }) data: DCTextBlock;

  rootClass = "c-dc-text";

  get layout() {
    return this.data.acf_fc_layout;
  }

  get textToDisplay() {
    return this.data[this.layout];
  }

  get smartTextType() {
    return TEXT_MAP[this.layout]
      ? TEXT_MAP[this.layout]
      : TEXT_MAP[DCTextModules.NORMAL];
  }

  get isWysiwyg() {
    return this.layout !== DCTextModules.HEADLINE;
  }

  get classes() {
    const root = `${this.rootClass}__block`;
    return [root];
  }
}
