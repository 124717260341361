
import { Mixins, Component, Prop, Ref } from "vue-property-decorator";
import { GSAP, config } from "_core";
import { onResize, onView } from "_mixins";
import { generateID } from "_utils";
import Chip from "../Chip/index.vue";

@Component({
  name: "Carousel",
  components: {
    Chip,
  },
})

export default class Carousel extends Mixins(onResize, onView) {
  @Prop({ default: true }) colored: boolean;
  @Prop({ required: true, type: Array }) texts: string[];
  @Ref() roller: HTMLElement;

  rootClass = "c-carousel";
  rollerAnimation;
  rollAmount = 0;
  repeat = 2;

  get uniqueID() {
    return generateID();
  }

  onView(inView: boolean) {
    if (inView && !this.rollerAnimation) {
      setTimeout(() => this.createRoller(), 100);
    }
  }

  kill() {
    if (this.rollerAnimation) this.rollerAnimation.kill();
  }

  createRoller() {
    this.repeat = 1;
    this.rollAmount = 0;
    const target = this.roller.firstElementChild as HTMLElement;
    const margin = window ? parseInt(window.getComputedStyle(target)["margin-right"]) : 0;
    if (target) {
      this.repeat = Math.ceil((config.dom.width * 2) / target.offsetWidth) + 1;
      this.rollAmount = target.offsetWidth + margin;
      this.setRollerAnimation();
    }
  }

  onResize() {
    this.kill();
    this.createRoller();
  }

  private setRollerAnimation() {
    this.rollerAnimation = GSAP.fromTo(
      this.roller,
      { x: 0 },
      {
        x: this.rollAmount * -1,
        ease: "linear",
        repeat: -1,
        duration: 50,
      }
    );
  }
}
