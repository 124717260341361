
import { Vue, Component, Prop } from "vue-property-decorator";
import { SmartImage, SmartImageProps, SmartText, WYSIWYG } from "_components";
import { DynamicContentImageProps } from "../../types";

@Component({
  name: "DynamicContentImage",
  components: { SmartImage, SmartText, WYSIWYG },
})
export default class DynamicContentImage extends Vue {
  @Prop({ required: true }) data: DynamicContentImageProps;
  rootClass = "c-dc-image";

  get imageSettings(): SmartImageProps {
    return (
      this.data?.image && {
        image: this.data.image,
        lazyload: true,
        fit: "contain",
      }
    );
  }

  get headline() {
    return this.data && this.data?.caption?.headline;
  }

  get text() {
    return this.data && this.data?.caption?.text;
  }
}
