import { ThemeLink, WPImage } from "_types";

export enum DCTextModules {
    HEADLINE = 'headline',
    LARGE = 'large',
    NORMAL = 'normal',
    SMALL = 'small'
}

export type DCTextBlock = {
    acf_fc_layout: DCTextModules;
    headline?: string;
    large?: string;
    normal?: string;
    small?: string;
}