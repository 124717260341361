
import { Vue, Component, Prop, Ref } from "vue-property-decorator";
import {
  WYSIWYG,
  SmartText,
  SmartSVG,
  SVGLibraryCustom,
  SmartImage,
  SmartImageProps,
} from "_components";
import { FooterSettings, RouterPageNames, THEME_LINK_TYPE } from "_types";
import LinkButton from "../LinkButton/index.vue";
import NavigationButton from "../NavigationButton/index.vue";
import Carousel from "../Carousel/index.vue";
import { generateID } from "_utils";

@Component({
  name: "Footer",
  components: {
    LinkButton,
    WYSIWYG,
    SmartText,
    SmartSVG,
    Carousel,
    NavigationButton,
    SmartImage,
  },
})
export default class Footer extends Vue {
  @Prop({ required: true }) footerSettings: FooterSettings;
  @Ref() wrapper: HTMLElement;
  rootClass = "c-footer";
  SVGLibraryCustom = SVGLibraryCustom;

  public elementsToAnimate() {
    return this.wrapper ? [this.wrapper] : [];
  }

  get showExtended() {
    return this.$route.name === RouterPageNames.HOME;
  }

  get uniqueID() {
    return generateID();
  }

  get introText() {
    return this.footerSettings && this.footerSettings.intro_text;
  }

  get supportingTexts() {
    return this.footerSettings && this.footerSettings.supporting_texts;
  }

  get emailAddress() {
    return this.footerSettings && this.footerSettings.email;
  }

  get emailAddressLink() {
    return {
      label: this.emailAddress,
      type: THEME_LINK_TYPE.EXTERNAL_URL,
      external_url: `mailto:${this.emailAddress}`,
    };
  }

  get socialLinks() {
    return this.footerSettings && this.footerSettings.social_links;
  }

  get team() {
    return this.footerSettings && this.footerSettings.team;
  }

  getTeamMemberEmailLink(email) {
    return {
      label: email,
      type: THEME_LINK_TYPE.EXTERNAL_URL,
      external_url: `mailto:${email}`,
    };
  }

  get carouselTexts() {
    return (
      this.footerSettings &&
      this.footerSettings.carousel &&
      this.footerSettings.carousel.map((text) => text.text)
    );
  }

  get logoPhrase() {
    return this.footerSettings && this.footerSettings.logo_phrase;
  }

  get copyrightText() {
    return this.footerSettings && this.footerSettings.copyright_text;
  }

  get partnersTitle() {
    return this.footerSettings && this.footerSettings.partners_title;
  }

  get partners() {
    return this.footerSettings && this.footerSettings.partners;
  }

  get enablePartners() {
    return this.footerSettings && this.footerSettings.enable_partners;
  }

  getImageSettings(image): SmartImageProps {
    return (
      image && {
        image,
        forceHeight: true,
        lazyload: false,
        fit: "contain",
      }
    );
  }
}
