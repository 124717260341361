
import { Vue, Component, Prop } from "vue-property-decorator";
import { SmartText } from "_components";
import { LinkLogic } from "../components";
import {
  ThemeLink,
  THEME_LINKS,
  THEME_LINK_TYPE,
} from "_types";
import { getRouterLink } from "_utils";

@Component({
  name: "LinkButton",
  components: { LinkLogic, SmartText },
})
export default class LinkButton extends Vue {
  @Prop() link: ThemeLink;
  @Prop({ type: String, required: false }) label: string;
  @Prop({ type: Boolean, default: false }) hasArrows: boolean;
  @Prop({ type: String, default: "hero-p" }) textType: string;
  @Prop({ type: Boolean, default: false }) hasHoverColor: boolean;
  @Prop({ default: false, type: Boolean }) bold: boolean;

  rootClass = "c-link-button";

  get url() {
    const { link } = this;
    switch (link.type) {
      case THEME_LINK_TYPE.PAGE_LINK:
        return link.page_link && getRouterLink(link.page_link);
      case THEME_LINK_TYPE.THEME_LINK:
        switch (link.theme_link) {
          case THEME_LINKS.HOME_PAGE:
            return "/";
          case THEME_LINKS.ALL_ARTICLES:
            return "/posts/";
          case THEME_LINKS.SEARCH:
            return `/search?query=${link.query}`;
          default:
            return "";
        }
      case THEME_LINK_TYPE.DOWNLOAD:
        return link.file_download;
      case THEME_LINK_TYPE.EXTERNAL_URL:
        return link.external_url;
      default:
        return "";
    }
  }

  get isOnCurrentPage() {
    return this.$route.path === this.url
  }
}
