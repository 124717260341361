/*
    Add SVGs to be accessible to CL and SmartSVG by simply
    adding it to the ./library folder and adding an enum
    matching the file name below.
*/

export enum SVGLibraryCustom {
  BoilerplateBackButton = "boilerplate-back-button",
  BoilerplateClose = "boilerplate-close",
  BoilerplateCopy = "boilerplate-copy",
  BoilerplateError = "boilerplate-error",
  LhLogo = "lh-logo",
  MONLogoSmall = "mon-logo-small",
  MONArrow = "mon-arrow",
  MONLogoNature = "mon-logo-nature",
  MONWobble = "mon-wobble",
  MONWobbleWhite = "mon-wobble-white",
  MONX = "mon-x",
  MONFooterStart = 'mon-footer-start',
  MONFooterEnd = 'mon-footer-end',
  MONFooterAll = 'mon-footer-all',
  CONLogoMob = 'con-logo-mob',
}
