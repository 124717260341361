import "url-polyfill";
import Vue from "vue";
import Meta from "vue-meta";
import Router from "vue-router";
import { PasswordProtectorPage } from "_components";
import { Routes } from "@app/routes";
import { EventBus, PasswordProtector } from "_core";
import { RouterPageNames } from "../types/types";

Vue.use(Router);
Vue.use(Meta);

// @ts-ignore
const baseUrl = new URL(window.BASE_URL).pathname;

export const router = new Router({
  routes: [{
    path: "/guard",
    name: "site-guard",
    component: PasswordProtectorPage,
  }, ...Routes],
  mode: "history",
  base: baseUrl,
  scrollBehavior(to, from, savedPosition) {
    if (from.name === RouterPageNames.HOME && to.name === RouterPageNames.HOME && !to.hash) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      return;
    }
    if (to.hash) {
      let offset = 0;
      const header = document.querySelector('#header');
      if (header) {
        offset = header.clientHeight;
      }

      if (from.path === to.path) {
        return {
          selector: to.hash,
          behavior: 'smooth',
          offset: { y: offset, x: 0 },
        };
      } else { // Delay scrolling to the anchor if not on the homepage
        return new Promise((resolve) => {
          setTimeout(() => {
            EventBus.$emit('anim:page')
            resolve({
              selector: to.hash,
              behavior: 'smooth',
              offset: { y: offset, x: 0 },
            });
          }, 500); // Wait for 500ms before scrolling to the anchor
        });
      }
    }

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        EventBus.$emit('anim:page')
        resolve({ x: 0, y: 0 })
      }, 350)
    })
    // }
  },
});

PasswordProtector.setupPasswordProtection(router);