
import { Mixins, Component, Prop, Watch, Ref } from "vue-property-decorator";
import { onScroll } from "_mixins";
import { Cookies } from "_core";
import { MainSettings, RouterPageNames } from "_types";
import { SmartText, WYSIWYG } from "_components";
import NavigationButton from "../NavigationButton/index.vue";

@Component({
  name: "CookieDialog",
  components: { SmartText, WYSIWYG, NavigationButton },
})
export default class CookieDialog extends Mixins(onScroll) {
  @Ref() cookieRef: HTMLElement;
  @Prop({ required: true }) settings: MainSettings;

  rootClass = "c-cookie-dialog";
  showCookieDialog = false;
  enableListener = false;
  scrollPos = 0;

  @Watch("translations")
  onGdprSettingsLoad() {
    if (
      !Cookies.isCookiesEnabled &&
      Boolean(this.translations.cookie_dialog_message)
    ) {
      this.showCookieDialog = true;
      this.$nextTick(() => (this.enableListener = true));
    }
  }

  onScroll(scroll: number) {
    if (this.$route.name !== RouterPageNames.HOME) return;
    if (!this.showCookieDialog) return;
    this.scrollPos = scroll;
  }

  get showCookieDialogProxy() {
    if (!this.showCookieDialog) return false;
    if (this.$route.name !== RouterPageNames.HOME) return this.showCookieDialog;
    else {
      if (this.enableListener && this.scrollPos < this.cookieRef.offsetHeight)
        return false;
      else return this.showCookieDialog;
    }
  }

  get translations() {
    const { settings } = this;
    return (
      settings && settings.general && settings.general.translation_keys.gdpr
    );
  }

  get dialogMessage() {
    return this.translations && this.translations.cookie_dialog_message;
  }

  get accept() {
    return this.translations && this.translations.accept;
  }

  get reject() {
    return this.translations && this.translations.reject;
  }

  get link() {
    return this.translations && this.translations.link;
  }

  get cookieTitle() {
    return this.translations && this.translations.cookie_title;
  }

  handleAccept() {
    Cookies.enableCookies();
    this.showCookieDialog = false;
  }

  handleReject() {
    // Cookies.disableCookies();
    this.showCookieDialog = false;
  }
}
