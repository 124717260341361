
import { Vue, Component, Prop } from "vue-property-decorator";
import { SmartText } from "_components";
import { generateID } from "_utils";
import { DynamicContentTextProps } from "../../types";
import SingleTextBlock from "./TextBlock.vue";
@Component({
  name: "DCText",
  components: { SmartText, SingleTextBlock },
})
export default class DCText extends Vue {
  @Prop({ required: true }) data: DynamicContentTextProps;

  rootClass = "c-dc-text";

  get uniqueID() {
    return generateID();
  }

  get generateClass() {
    return [
      this.rootClass,
    ];
  }

  get textElements() {
    return this.data.text_selector.length && this.data.text_selector;
  }
}
