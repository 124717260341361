
import { Component, Prop, Mixins, Ref } from "vue-property-decorator";
import { onResize, onScroll } from "_mixins";
import { Locale, config, GSAP, Circ, EventBus, EventBusChannels } from "_core";
import {
  SmartText,
  LinkButton,
  SVGLibraryCustom,
  SmartSVG,
  NavigationButton,
} from "_components";
import { MainSettings, RouterPageNames } from "_types";
import { clamp, mapAnimation, lerp } from "_utils";

import HeaderMenu from "./Menu.vue";

@Component({
  components: { SmartText, LinkButton, SmartSVG, HeaderMenu, NavigationButton },
})
export default class Header extends Mixins(onScroll, onResize) {
  @Ref() gradientRef: HTMLElement;
  @Ref() blurRef: HTMLElement;

  @Ref() leftRef: Vue;
  @Ref() middleRef: HTMLElement;
  @Ref() rightRef: HTMLElement;

  @Prop({ required: true }) settings: MainSettings;

  rootClass = "c-header";
  SVGLibraryCustom = SVGLibraryCustom;
  isOpen = false;
  measurements = null;
  cached = 0;
  target = 0;

  private tl: gsap.core.Timeline;

  get showHomeButtonNonLinked() {
    const { name, fullPath } = this.$route;
    return name === RouterPageNames.HOME && fullPath === "/";
  }

  mounted() {
    this.anim();
    this.setupListeners(true);
  }

  anim() {
    const elem = [];
    if (this.leftRef) elem.push(this.leftRef);
    if (this.middleRef) elem.push(this.middleRef);
    if (this.rightRef) elem.push(this.rightRef);

    this.tl = GSAP.timeline();
    this.tl.fromTo(
      elem,
      { y: -30, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        stagger: 0.3,
        ease: Circ.easeOut,
        clearProps: "transform, opacity",
      }
    );
  }

  beforeDestroy() {
    this.setupListeners(false);
  }

  setupListeners(toggle: boolean) {
    if (toggle) {
      this.calc();
      document.body.addEventListener("mousemove", this.handleMouseMove);
      EventBus.$on(EventBusChannels.ChangingPage, this.handlePageChange);
    } else {
      document.body.removeEventListener("mousemove", this.handleMouseMove);
      EventBus.$off(EventBusChannels.ChangingPage, this.handlePageChange);
    }
  }

  onResize() {
    this.calc();
  }

  handleMouseMove(event: MouseEvent) {
    if (!this.measurements) return;
    const { screenWidth } = this.measurements;
    this.target = Math.round(
      clamp(
        mapAnimation([0, screenWidth], [-100, 100], event.clientX),
        -100,
        100
      )
    );
  }

  calc() {
    this.measurements = {
      screenWidth: config.dom.width,
      distance: this.gradientRef.offsetWidth / 4,
    };
  }

  onScroll() {
    if (this.measurements) this.gradient();
  }

  gradient() {
    const { distance } = this.measurements;
    const lerped = lerp(this.cached, this.target);
    this.cached = lerped;

    const transX = Math.round(
      clamp(
        mapAnimation([-100, 100], [distance * -1, distance], lerped),
        distance * -1,
        distance
      )
    );

    this.blurRef.style.transform = `translate3D(${transX}px, 0, 0)`;
  }

  toggleMenu() {
    this.isOpen = !this.isOpen;
  }

  get headerLinks() {
    return this.settings.header && this.settings.header.links_in_header
      ? this.settings.header.links_in_header
      : [];
  }

  get languages() {
    return Locale.availableLanguages ? Locale.availableLanguages : [];
  }

  get enableLangSelector() {
    return Locale.isEnabled;
  }

  get currentLanguage() {
    return Locale.currentLocale ? Locale.currentLocale : null;
  }

  get otherLanguage() {
    return this.languages.filter(
      (language) => language.locale !== this.currentLanguage
    )[0];
  }

  switchLocale() {
    Locale.switchLocale(this.otherLanguage.locale, this.$route);
  }

  handlePageChange() {
    if (this.isOpen) this.isOpen = false;
  }

  handleHomeClick() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
}
