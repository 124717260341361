
import { Vue, Component, Prop, Watch, Ref } from "vue-property-decorator";
import { createModifierClass, generateID } from "_utils";
import { Locale, GSAP, Circ } from "_core";
import { SVGLibraryCustom, SmartSVG, LinkButton, SmartText } from "_components";

@Component({
  name: "HeaderMenu",
  components: { SmartSVG, LinkButton, SmartText },
})
export default class HeaderMenu extends Vue {
  @Prop() headerLinks: any[];
  @Prop({ type: Boolean, default: false }) isOpen: boolean;

  @Ref() wrapperRef: HTMLElement;
  @Ref() backgroundRef: HTMLElement;
  @Ref() logoRef: HTMLElement;
  @Ref() closeRef: HTMLElement;
  @Ref() headerLinksRef: HTMLElement;
  @Ref() backgroundFrameRef: HTMLElement;

  rootClass = "c-header-menu";
  private tlSet: gsap.core.Timeline;
  private tlIn: gsap.core.Timeline;
  private tlOut: gsap.core.Timeline;
  SVGLibraryCustom = SVGLibraryCustom;

  @Watch("isOpen")
  handleMenuToggle() {
    if (this.isOpen) this.animIn();
    else this.animOut();
  }

  mounted() {
    this.setupAnim();
  }

  get uniqueID() {
    return generateID();
  }

  get generateClass() {
    return [
      this.rootClass,
      createModifierClass(this.rootClass, this.isOpen, "is-open"),
    ];
  }

  setupAnim() {
    this.tlSet = GSAP.timeline();
    this.tlSet.set(this.backgroundRef, {
      y: "-110%",
      opacity: 0,
    });
    this.tlSet.set(this.logoRef, { opacity: 0 });
    this.tlSet.set(this.closeRef, { opacity: 0 });
    this.tlSet.set(this.headerLinksRef.children, {
      y: -50,
      opacity: 0,
    });
    this.tlSet.set(this.backgroundFrameRef, { scale: 1.3 });
  }

  animIn() {
    this.tlIn = GSAP.timeline();
    this.tlIn.to(this.backgroundRef, {
      y: 0,
      opacity: 1,
      duration: 0.4,
      ease: Circ.easeOut,
    });
    this.tlIn.to(
      this.logoRef,
      { opacity: 1, duration: 0.25, ease: Circ.easeOut },
      "-=0.4"
    );
    this.tlIn.to(
      this.closeRef,
      { opacity: 1, duration: 0.25, ease: Circ.easeOut },
      "-=0.1"
    );
    this.tlIn.to(
      this.headerLinksRef.children,
      {
        y: 0,
        opacity: 1,
        stagger: 0.1,
        ease: Circ.easeOut,
      },
      "-=0.5"
    );
    this.tlIn.to(
      this.backgroundFrameRef,
      { scale: 1, duration: 1, ease: Circ.easeOut },
      "-=0.3"
    );
    if (this.tlOut) {
      this.tlOut.kill();
    }
  }

  animOut() {
    this.tlOut = GSAP.timeline();
    this.tlOut.to(this.backgroundRef, {
      y: "-110%",
      opacity: 0,
      duration: 0.7,
      ease: Circ.easeIn,
    });
    this.tlOut.to(
      this.backgroundFrameRef,
      { scale: 1.3, duration: 0.6, ease: Circ.easeIn },
      "-=0.7"
    );
    this.tlOut.to(
      this.headerLinksRef.children,
      {
        y: -50,
        opacity: 0,
        // duration: 0.2,
        stagger: 0.05,
        ease: Circ.easeIn,
      },
      "-=0.9"
    );
    this.tlOut.to(this.logoRef, { opacity: 0, ease: Circ.easeIn }, "-=0.3");
    this.tlOut.to(this.closeRef, { opacity: 0, ease: Circ.easeIn }, "-=0.8");
    if (this.tlIn) {
      this.tlIn.kill();
    }
  }

  get languages() {
    return Locale.availableLanguages ? Locale.availableLanguages : [];
  }

  get enableLangSelector() {
    return Locale.isEnabled;
  }

  get currentLanguage() {
    return Locale.currentLocale ? Locale.currentLocale : null;
  }

  get otherLanguage() {
    return this.languages.filter(
      (language) => language.locale !== this.currentLanguage
    )[0];
  }

  switchLocale() {
    Locale.switchLocale(this.otherLanguage.locale, this.$route);
  }
}
