
import { DevTools, CookieDialog } from "_components";
import Header from "./components/Header/index.vue";
import { Route } from "vue-router/types/router";
import { Component, Mixins, Ref } from "vue-property-decorator";
import { createApp } from "_mixins";
import { RouterPageNames } from "./boilerplate/types/types";

@Component({
  components: {
    DevTools,
    Header,
    CookieDialog,
  },
})
export default class Boilerplate extends Mixins(createApp) {
  @Ref() pageRef;
  $projectName: string;

  get routerKey() {
    if (this.$route.name === RouterPageNames.HOME) return RouterPageNames.HOME;
    else return this.$route.fullPath;
  }

  onPageChange(to: Route, from: Route, next: () => void): void {
    // Trigger page animation from here
    next();
  }
}
