
import { Vue, Component, Prop } from "vue-property-decorator";
import { SmartText, SVGLibraryCustom } from "_components";
import { createModifierClass } from "_utils";

@Component({
  name: "Chip",
  components: { SmartText },
})

export default class Chip extends Vue {
  @Prop({ default: true }) colored: boolean;
  @Prop({ type: String }) label: string;

  rootClass = "c-chip";
  SVGLibraryCustom = SVGLibraryCustom;
  get classes() {
    return [
      this.rootClass,
      ...createModifierClass(this.rootClass, this.colored, "colored")
    ];
  }
}
